// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 新增 POST /admin/api-v1/goods-category-dcs */
export async function save(
  body: Manage.CategoryDcsSaveReq,
  options?: { [key: string]: any }
) {
  return request<Manage.RVoid>("/admin/api-v1/goods-category-dcs", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /admin/api-v1/goods-category-dcs/${param0} */
export async function details(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsCategoryDcsDetailsParams,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RGoodsCategoryDcsDetailsRsp>(
    `/admin/api-v1/goods-category-dcs/${param0}`,
    {
      method: "GET",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 修改 PUT /admin/api-v1/goods-category-dcs/${param0} */
export async function update(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsCategoryDcsUpdateParams,
  body: Manage.CategoryDcsSaveReq,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RVoid>(`/admin/api-v1/goods-category-dcs/${param0}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 删除 DELETE /admin/api-v1/goods-category-dcs/${param0} */
export async function deleteUsingDelete(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsCategoryDcsDeleteParams,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RVoid>(`/admin/api-v1/goods-category-dcs/${param0}`, {
    method: "DELETE",
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 列表 GET /admin/api-v1/goods-category-dcs/list */
export async function list(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsCategoryDcsListParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RCollectionGoodsCategoryDcsListRsp>(
    "/admin/api-v1/goods-category-dcs/list",
    {
      method: "GET",
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}

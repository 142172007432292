// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 列表 GET /admin/api-v1/merchant-dangerous-cert/${param0}/list */
export async function list(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.MerchantDangerousCertListParams,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RListMerchantDangerousCertResponse>(
    `/admin/api-v1/merchant-dangerous-cert/${param0}/list`,
    {
      method: "GET",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

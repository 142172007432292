// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 统计分页列表 GET /admin/api-v1/manager/statistics */
export async function statistics(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.DcsManagerStatisticsStatisticsParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RIPageDcsManagerStatisticsResponse>(
    "/admin/api-v1/manager/statistics",
    {
      method: "GET",
      params: {
        // pageNo has a default value: 1
        pageNo: "1",
        // pageSize has a default value: 20
        pageSize: "20",
        ...params,
      },
      ...(options || {}),
    }
  );
}

/** 商品统计导出 GET /admin/api-v1/manager/statistics-goods-export/${param0} */
export async function goodsStatisticsExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.DcsManagerStatisticsGoodsStatisticsExportParams,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<any>(
    `/admin/api-v1/manager/statistics-goods-export/${param0}`,
    {
      method: "GET",
      params: {
        ...queryParams,
      },
      ...(options || {}),
    }
  );
}

/** 商品统计分页列表 GET /admin/api-v1/manager/statistics-goods/${param0} */
export async function goodsStatistics(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.DcsManagerStatisticsGoodsStatisticsParams,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RIPageDcsGoodsStatisticsResponse>(
    `/admin/api-v1/manager/statistics-goods/${param0}`,
    {
      method: "GET",
      params: {
        // pageNo has a default value: 1
        pageNo: "1",
        // pageSize has a default value: 20
        pageSize: "20",
        ...queryParams,
      },
      ...(options || {}),
    }
  );
}

/** 导出统计 GET /admin/api-v1/manager/statistics/export */
export async function statisticsExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.DcsManagerStatisticsStatisticsExportParams,
  options?: { [key: string]: any }
) {
  return request<any>("/admin/api-v1/manager/statistics/export", {
    method: "GET",
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

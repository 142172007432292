<template>
  <!-- 国际化 -->
  <a-config-provider :locale="zhCN">
    <router-view />
    <!-- <dcmMenu /> -->
  </a-config-provider>
</template>
<script setup lant="ts">
// import dcmMenu from "@/menu/dcmMenu.vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('en');
</script>
<style lang="less">
#app {
  margin: 0;
  padding: 0;
}
</style>

// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 获取指定商户信息 GET /admin/api-v1/merchant/${param0} */
export async function details(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.MerchantDetailsParams,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RMerchantDetailsResp>(
    `/admin/api-v1/merchant/${param0}`,
    {
      method: "GET",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 更新商户 PUT /admin/api-v1/merchant/${param0} */
export async function update(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.MerchantUpdateParams,
  body: Manage.MerchantSaveReq,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RVoid>(`/admin/api-v1/merchant/${param0}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 【启用|禁用】商户 PUT /admin/api-v1/merchant/${param0}/status */
export async function updateStatus(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.MerchantUpdateStatusParams,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RVoid>(`/admin/api-v1/merchant/${param0}/status`, {
    method: "PUT",
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 添加商户 POST /admin/api-v1/merchant/add */
export async function add(
  body: Manage.MerchantSaveReq,
  options?: { [key: string]: any }
) {
  return request<Manage.RVoid>("/admin/api-v1/merchant/add", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 商户的营业执照信息 GET /admin/api-v1/merchant/get/${param0}/license-info */
export async function getLicenseInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.MerchantGetLicenseInfoParams,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RMerchantLicenseInfoResponse>(
    `/admin/api-v1/merchant/get/${param0}/license-info`,
    {
      method: "GET",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 营业执照上传地址 GET /admin/api-v1/merchant/license-upload-url */
export async function getLicenseUploadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.MerchantGetLicenseUploadUrlParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RPresignedUrlResponse>(
    "/admin/api-v1/merchant/license-upload-url",
    {
      method: "GET",
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}

/** 商户的简单信息列表 GET /admin/api-v1/merchant/list/simple-info */
export async function listMerchantLicenseInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.MerchantListMerchantLicenseInfoParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RListMerchantSimpleInfoResponse>(
    "/admin/api-v1/merchant/list/simple-info",
    {
      method: "GET",
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}

/** 公司Logo上传地址 GET /admin/api-v1/merchant/logo-upload-url */
export async function getLogoUploadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.MerchantGetLogoUploadUrlParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RPresignedUrlResponse>(
    "/admin/api-v1/merchant/logo-upload-url",
    {
      method: "GET",
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}

/** 商户列表 GET /admin/api-v1/merchant/page */
export async function page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.MerchantPageParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RIPageMerchantPageResp>("/admin/api-v1/merchant/page", {
    method: "GET",
    params: {
      // pageNo has a default value: 1
      pageNo: "1",
      // pageSize has a default value: 20
      pageSize: "20",
      ...params,
    },
    ...(options || {}),
  });
}

// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 新增 POST /admin/api-v1/merchant-goods/${param0} */
export async function save(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsMerchantSaveParams,
  body: Manage.GoodsMerchantSaveMapReq,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RVoid>(`/admin/api-v1/merchant-goods/${param0}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 删除 DELETE /admin/api-v1/merchant-goods/${param0}/${param1} */
export async function deleteUsingDelete(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsMerchantDeleteParams,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, goodsId: param1, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/merchant-goods/${param0}/${param1}`,
    {
      method: "DELETE",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 修改 PUT /admin/api-v1/merchant-goods/${param0}/${param1} */
export async function update(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsMerchantUpdateParams,
  body: Manage.GoodsMerchantSaveMapReq,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, id: param1, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/merchant-goods/${param0}/${param1}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...queryParams },
      data: body,
      ...(options || {}),
    }
  );
}

/** 编辑详情 GET /admin/api-v1/merchant-goods/${param0}/${param1}/edit-details */
export async function editDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsMerchantEditDetailParams,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, goodsId: param1, ...queryParams } = params;
  return request<Manage.RListGoodsAttrResp>(
    `/admin/api-v1/merchant-goods/${param0}/${param1}/edit-details`,
    {
      method: "GET",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 授权商品列表 GET /admin/api-v1/merchant-goods/${param0}/authorized-list */
export async function authorizedList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsMerchantAuthorizedListParams,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RCollectionGoodsAuthorizedListResponse>(
    `/admin/api-v1/merchant-goods/${param0}/authorized-list`,
    {
      method: "GET",
      params: {
        ...queryParams,
      },
      ...(options || {}),
    }
  );
}

/** 商品统计 GET /admin/api-v1/merchant-goods/${param0}/count */
export async function count(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsMerchantCountParams,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RGoodsCountResponse>(
    `/admin/api-v1/merchant-goods/${param0}/count`,
    {
      method: "GET",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 分页 GET /admin/api-v1/merchant-goods/${param0}/page */
export async function page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsMerchantPageParams,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RIPageMerchantGoodsPageRsp>(
    `/admin/api-v1/merchant-goods/${param0}/page`,
    {
      method: "GET",
      params: {
        // pageNo has a default value: 1
        pageNo: "1",
        // pageSize has a default value: 20
        pageSize: "20",
        ...queryParams,
      },
      ...(options || {}),
    }
  );
}

/** 上下架-商品 PUT /admin/api-v1/merchant-goods/${param0}/up-down */
export async function upDownGoods(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsMerchantUpDownGoodsParams,
  body: Manage.MerchantGoodsUpDownReq,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/merchant-goods/${param0}/up-down`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...queryParams },
      data: body,
      ...(options || {}),
    }
  );
}

/** 商户-审核上下架商户商品 PUT /admin/api-v1/merchant-goods/${param0}/up-down-audit */
export async function upDownAuditMerchant(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsMerchantUpDownAuditMerchantParams,
  body: Manage.MerchantGoodsUpDownReq,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/merchant-goods/${param0}/up-down-audit`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...queryParams },
      data: body,
      ...(options || {}),
    }
  );
}

/** 批量上下架 PUT /admin/api-v1/merchant-goods/${param0}/up-down-batch */
export async function upDownBatch(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsMerchantUpDownBatchParams,
  body: Manage.PutMerchantGoodsUpDownBatchRequest,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/merchant-goods/${param0}/up-down-batch`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...queryParams },
      data: body,
      ...(options || {}),
    }
  );
}

/** 上下架-品牌 PUT /admin/api-v1/merchant-goods/${param0}/up-down/brand */
export async function upDownBrand(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsMerchantUpDownBrandParams,
  body: Manage.GoodsMerchantUpDownBrandReq,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/merchant-goods/${param0}/up-down/brand`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...queryParams },
      data: body,
      ...(options || {}),
    }
  );
}

/** 修改商品规格 PUT /admin/api-v1/merchant-goods/${param0}/update-dcs-goods-specs */
export async function updateDcsSpec(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsMerchantUpdateDcsSpecParams,
  body: Manage.GoodsSpecMerchantPriceReq[],
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/merchant-goods/${param0}/update-dcs-goods-specs`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...queryParams },
      data: body,
      ...(options || {}),
    }
  );
}

/** 图库 GET /admin/api-v1/merchant-goods/cover-images */
export async function coverImages(options?: { [key: string]: any }) {
  return request<Manage.RCollectionGoodsCoverImageResp>(
    "/admin/api-v1/merchant-goods/cover-images",
    {
      method: "GET",
      ...(options || {}),
    }
  );
}

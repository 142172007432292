const clearReactiveValues = (obj) => {
    for (const key in obj) {
      if (obj[key] instanceof Object) {
        clearReactiveValues(obj[key]); // 递归清空嵌套对象
      } else {
        // 设置初始值，这里以空字符串为例，可以根据实际类型进行调整
        obj[key] = undefined;
      }
    }
  };
  export default clearReactiveValues;
// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 详情 GET /admin/api-v1/enquiry-order-dcs/${param0} */
export async function details(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.OrderEnquiryOrderDcsDetailsParams,
  options?: { [key: string]: any }
) {
  const { enquiryOrderId: param0, ...queryParams } = params;
  return request<Manage.ROrderEnquiryDetailsResponse>(
    `/admin/api-v1/enquiry-order-dcs/${param0}`,
    {
      method: "GET",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 新增备注 POST /admin/api-v1/enquiry-order-dcs/${param0}/remark */
export async function addMerchantRemark(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.OrderEnquiryOrderDcsAddMerchantRemarkParams,
  body: Manage.SaveUserRemarkRequest,
  options?: { [key: string]: any }
) {
  const { enquiryOrderId: param0, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/enquiry-order-dcs/${param0}/remark`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...queryParams },
      data: body,
      ...(options || {}),
    }
  );
}

/** 关闭 PUT /admin/api-v1/enquiry-order-dcs/close/${param0} */
export async function close(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.OrderEnquiryOrderDcsCloseParams,
  options?: { [key: string]: any }
) {
  const { enquiryOrderId: param0, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/enquiry-order-dcs/close/${param0}`,
    {
      method: "PUT",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 列表 GET /admin/api-v1/enquiry-order-dcs/page */
export async function page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.OrderEnquiryOrderDcsPageParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RIPageOrderEnquiryPageResponse>(
    "/admin/api-v1/enquiry-order-dcs/page",
    {
      method: "GET",
      params: {
        // pageNo has a default value: 1
        pageNo: "1",
        // pageSize has a default value: 20
        pageSize: "20",
        ...params,
      },
      ...(options || {}),
    }
  );
}

/** 报价 PUT /admin/api-v1/enquiry-order-dcs/quote/${param0} */
export async function quote(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.OrderEnquiryOrderDcsQuoteParams,
  body: Manage.PutQuotedPriceEnquiryOrderRequest,
  options?: { [key: string]: any }
) {
  const { enquiryOrderId: param0, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/enquiry-order-dcs/quote/${param0}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...queryParams },
      data: body,
      ...(options || {}),
    }
  );
}

/** 反馈 PUT /admin/api-v1/enquiry-order-dcs/usr/feedback/${param0} */
export async function feedbackUsr(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.OrderEnquiryOrderDcsFeedbackUsrParams,
  body: Manage.PutOrderEnquiryFeedbackRequest,
  options?: { [key: string]: any }
) {
  const { enquiryOrderId: param0, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/enquiry-order-dcs/usr/feedback/${param0}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...queryParams },
      data: body,
      ...(options || {}),
    }
  );
}

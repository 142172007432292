// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 新增商品 POST /admin/api-v1/dcs-goods */
export async function save(
  body: Manage.GoodsDcsSaveMapReq,
  options?: { [key: string]: any }
) {
  return request<Manage.RVoid>("/admin/api-v1/dcs-goods", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改商品 PUT /admin/api-v1/dcs-goods/${param0} */
export async function update(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsDcsUpdateParams,
  body: Manage.GoodsDcsSaveMapReq,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RVoid>(`/admin/api-v1/dcs-goods/${param0}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 删除商品 DELETE /admin/api-v1/dcs-goods/${param0} */
export async function deleteUsingDelete(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsDcsDeleteParams,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RVoid>(`/admin/api-v1/dcs-goods/${param0}`, {
    method: "DELETE",
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 编辑详情 GET /admin/api-v1/dcs-goods/${param0}/edit-details */
export async function editDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsDcsEditDetailParams,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RListGoodsAttrResp>(
    `/admin/api-v1/dcs-goods/${param0}/edit-details`,
    {
      method: "GET",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 图库 GET /admin/api-v1/dcs-goods/cover-images */
export async function coverImages(options?: { [key: string]: any }) {
  return request<Manage.RCollectionGoodsCoverImageResp>(
    "/admin/api-v1/dcs-goods/cover-images",
    {
      method: "GET",
      ...(options || {}),
    }
  );
}

/** 导入 DCS商品 POST /admin/api-v1/dcs-goods/import */
export async function importDcsGoods(
  body: {},
  options?: { [key: string]: any }
) {
  return request<Manage.RVoid>("/admin/api-v1/dcs-goods/import", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** DCS询价商品导入模板.xlsx GET /admin/api-v1/dcs-goods/import-template */
export async function importTemplate(options?: { [key: string]: any }) {
  return request<any>("/admin/api-v1/dcs-goods/import-template", {
    method: "GET",
    ...(options || {}),
  });
}

/** 分页列表 GET /admin/api-v1/dcs-goods/page */
export async function page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsDcsPageParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RIPageGoodsDcsPageResp>(
    "/admin/api-v1/dcs-goods/page",
    {
      method: "GET",
      params: {
        // pageNo has a default value: 1
        pageNo: "1",
        // pageSize has a default value: 20
        pageSize: "20",
        ...params,
      },
      ...(options || {}),
    }
  );
}

/** 上下架-批量 PUT /admin/api-v1/dcs-goods/up-down-batch */
export async function upDownBatch(
  body: Manage.GoodsDcsUpDownBatchReq,
  options?: { [key: string]: any }
) {
  return request<Manage.RVoid>("/admin/api-v1/dcs-goods/up-down-batch", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 上下架 PUT /admin/api-v1/dcs-goods/up-down/${param0} */
export async function updown(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsDcsUpdownParams,
  body: Manage.GoodsDcsUpDownReq,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RVoid>(`/admin/api-v1/dcs-goods/up-down/${param0}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

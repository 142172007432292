// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 修改折扣 PUT /admin/api-v1/goods-discount-dcs/${param0} */
export async function updateDiscount(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsDiscountDcsUpdateDiscountParams,
  body: Manage.PutDiscountRequest,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RVoid>(`/admin/api-v1/goods-discount-dcs/${param0}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 删除折扣 DELETE /admin/api-v1/goods-discount-dcs/${param0} */
export async function deleteUsingDelete(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsDiscountDcsDeleteParams,
  options?: { [key: string]: any }
) {
  const { id: param0, ...queryParams } = params;
  return request<Manage.RVoid>(`/admin/api-v1/goods-discount-dcs/${param0}`, {
    method: "DELETE",
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 设置品牌折扣 PUT /admin/api-v1/goods-discount-dcs/brand-discount */
export async function updateBrandDiscount(
  body: Manage.BrandDiscountUpdateReq,
  options?: { [key: string]: any }
) {
  return request<Manage.RVoid>(
    "/admin/api-v1/goods-discount-dcs/brand-discount",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
      ...(options || {}),
    }
  );
}

/** 全局折扣 GET /admin/api-v1/goods-discount-dcs/global */
export async function globalDiscount(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsDiscountDcsGlobalDiscountParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RInteger>("/admin/api-v1/goods-discount-dcs/global", {
    method: "GET",
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 设置全局折扣 PUT /admin/api-v1/goods-discount-dcs/global */
export async function updateGlobalDiscount(
  body: Manage.PutGlobalDiscountRequest,
  options?: { [key: string]: any }
) {
  return request<Manage.RVoid>("/admin/api-v1/goods-discount-dcs/global", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 设置商户品牌折扣 PUT /admin/api-v1/goods-discount-dcs/merchant-brand-discount */
export async function updateBrandDiscount2(
  body: Manage.MerchantBrandDiscountUpdateReq,
  options?: { [key: string]: any }
) {
  return request<Manage.RVoid>(
    "/admin/api-v1/goods-discount-dcs/merchant-brand-discount",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
      ...(options || {}),
    }
  );
}

/** 设置商户商品折扣 PUT /admin/api-v1/goods-discount-dcs/merchant-goods-discount */
export async function updateMerchantGoodsDiscount(
  body: Manage.PutGoodsDiscountRequest,
  options?: { [key: string]: any }
) {
  return request<Manage.RVoid>(
    "/admin/api-v1/goods-discount-dcs/merchant-goods-discount",
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
      ...(options || {}),
    }
  );
}

/** 折扣分页列表 GET /admin/api-v1/goods-discount-dcs/page */
export async function page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsDiscountDcsPageParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RIPageGoodsDiscountPageResponse>(
    "/admin/api-v1/goods-discount-dcs/page",
    {
      method: "GET",
      params: {
        // pageNo has a default value: 1
        pageNo: "1",
        // pageSize has a default value: 20
        pageSize: "20",
        ...params,
      },
      ...(options || {}),
    }
  );
}

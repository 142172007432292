// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 地址列表 GET /base/api-v1/base/address */
export async function addressCountry(options?: { [key: string]: any }) {
  return request<Manage.RCollectionAddress>("/base/api-v1/base/address", {
    method: "GET",
    ...(options || {}),
  });
}

/** 获取字典 GET /base/api-v1/base/dict */
export async function dict(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.BaseDictParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RCollectionDictResponse>("/base/api-v1/base/dict", {
    method: "GET",
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

<template>
  <div class="index">
    <div class="index-content">
      <div class="index-content-search">
        <div class="index-content-search-left">
          <a-input
            v-model:value="name"
            placeholder="请输入商户名称"
            style="width: 330px"
            @change="getName"
          >
            <!-- <template #prefix> <user-outlined type="user" />icon </template> -->
          </a-input>
          <a-button
            type="primary"
            style="margin-left: 16px; margin-right: 80px"
            @click="getlist"
            >查询</a-button
          >
          <a-select
            ref="select"
            v-model:value="value1"
            placeholder="是否启用"
            @change="handleChange"
            style="width: 136px"
          >
            <a-select-option value="">全部</a-select-option>
            <a-select-option value="1">启用</a-select-option>
            <a-select-option value="0">禁用</a-select-option>
          </a-select>
        </div>
        <div class="index-content-search-right">
          <a-button type="primary" @click="toAdd">新增商户</a-button>
        </div>
      </div>
      <div>
        <a-table
          :columns="columns"
          :data-source="tabledata.listData"
          :pagination="false"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'name'">
              <span
                @click="toDetails(record)"
                style="cursor: pointer; color: #0e5ab8"
                >{{ record.name }}</span
              >
            </template>

            <template v-if="column.key === 'status'">
              <a-switch
                v-model:checked="record.switchChecked"
                @click="(e) => changeStatus(e, record)"
              />
            </template>
            <template v-if="column.key === 'action'">
              <span class="editThis" @click="toDetails(record)">查看</span>
            </template>
          </template>
        </a-table>

        <PaginationPage :pager="pager" @emitPage="getPage" />
      </div>
    </div>

    <RegisterApplet
      :inletType="0"
      v-if="registerAppletVisible"
      :merchantInformation="tabledata.merchantInformation"
      @toCancel="toCancel"
    />
  </div>
</template>
<script setup>
import { reactive, ref, onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
import { message } from "ant-design-vue";
import * as merchantAdmin from "@/services/api/merchantAdmin";

import RegisterApplet from "@/views/dcsManage/components/registerApplet.vue";
import PaginationPage from "@/components/paginationPage.vue";

let registerAppletVisible = ref(false);

const columns = [
  {
    title: "商户名称",
    dataIndex: "name", //？name  merchantName
    key: "name",
  },
  {
    title: "营业执照号",
    dataIndex: "socialUnicode",
    key: "socialUnicode",
  },
  {
    title: "法人",
    dataIndex: "legalPerson",
    key: "legalPerson",
  },
  {
    title: "客户经理",
    dataIndex: "managerName",
    key: "managerName",
  },
  // {
  //   title: "赛化云注册手机号",
  //   dataIndex: "esailMobileNumber",
  //   key: "esailMobileNumber",
  // },
  {
    title: "添加时间",
    dataIndex: "createAt",
    key: "createAt",
  },
  {
    title: "是否启用",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "操作",
    dataIndex: "action",
    key: "action",
  },
];
let tabledata = reactive([{ listData: [], merchantInformation: {} }]);
const name = ref();
let pager = reactive({
  pageSize: 0,
  total: 0,
  pages: 0,
  pageNo: 0,
});

onBeforeMount(() => {
  const { name: Name, pageSize } = route.query;
  if (pageSize ?? true) {
    pager.pageSize = 20;
    pager.pageNo = 1;
    router
      .push({
        name: "merchantManagement",
        query: { ...route.query, pageSize: pageSize, pageNo: 1 },
      })
      .then(() => {
        getlist();
      });
  }
  name.value = Name;
});

const getPage = ({ page, pageSize }) => {
  router
    .push({
      name: "merchantManagement",
      query: { ...route.query, pageNo: page, pageSize: pageSize },
    })
    .then(() => {
      getlist();
    });
};
const getlist = () => {
  console.log(route.query);
  const params = {
    ...route.query,
  };

  merchantAdmin.page(params).then(({ code, data }) => {
    if (code === "0") {
      let {
        records,
        pageSize,
        totalRow,
        totalPage: pages,
        totalPage: pageNo,
      } = data;
      pager.pageSize = pageSize;
      pager.pages = pages;
      pager.total = totalRow;
      pager.pageNo = pageNo;
      records = records.map((item) => {
        return {
          ...item,
          switchChecked: item.status === 0 ? false : true,
          createAt: item.createAt.replace("T", "   "),
        };
      });
      tabledata.listData = records;
    }
  });
};

const changeStatus = (e, record) => {
  let params = {
    id: Number(record.id),
    status: record.switchChecked ? 1 : 0,
  };

  merchantAdmin.updateStatus( params).then(() => {
    message.success("商户状态更新成功");
  });
};

const toCancel = () => {
  registerAppletVisible.value = false;
};

const getName = (e) => {
  const {
    target: { value },
  } = e;
  router.push({ name: "merchantManagement", query: { name: value } });
};
const toAdd = () => {
  router.push({ name: "addMerchant", query: { type: "add" } });
};
const toDetails = (row) => {
  router.push({
    name: "merchantDetails",
    query: { id: row.id, type: "show", appId: row.appId },
  });
}
const value1= ref('')
const handleChange = () => {
  router
    .push({
      name: "merchantManagement",
      query: { ...route.query, status: value1.value, pageNo: 1 },
    })
    .then(() => {
      getlist();
    });
};
</script>
<style scoped lang="less">
.index {
  min-height: 100%;
  padding: 20px 0 0 20px;
  &-content {
    min-height: 100%;
    padding: 20px 22px 32px;
    background: #ffffff;
    background-color: white;
    &-search {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
}
.addMin {
  &-title {
    margin-bottom: 24px;
    color: #333333;
    font-size: 18px;
  }
  &-footer {
    display: flex;
    justify-content: center;
  }
}
.commodityName {
  cursor: pointer;
  color: #0e5ab8;
}
</style>

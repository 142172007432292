const currencyFn = (e) => {
    const temp = {
        '10': {
            price: '',
            currency: '￥',
            unit: '元',
            unitEN: 'CNY',
            countryCode: '10',
            countryEn: 'China',
            country: '中国',
        }, '20': {
            price: 'Aus',
            currency: 'A$',
            unit: '澳元',
            unitEN: 'AUD',
            countryCode: '20',
            countryEn: 'Australia',
            country: '澳大利亚',
        },
        '30': {
            price: 'My',
            currency: 'RM',
            unit: '林吉特',

            unitEN: 'MYR',
            countryCode: '30',
            countryEn: 'Malaysia',
            country: '马来西亚',
        },
        'CNY': {
            price: '',
            currency: '￥',
            unit: '元',
            unitEN: 'CNY',
            countryCode: '10',
            countryEn: 'China',
            country: '中国',
        },
        'AUD': {
            price: 'Aus',
            currency: 'A$',
            unit: '澳元',
  
            unitEN: 'AUD',
            countryCode: '20',
            countryEn: 'Australia',
            country: '澳大利亚',
        },
        'MYR': {
            price: 'My',
            currency: 'RM',
            unit: '林吉特',
            unitEN: 'MYR',
            countryCode: '30',
            countryEn: 'Malaysia',
            country: '马来西亚',
        }

    }
    return temp[e]
}


export default currencyFn;

import { RouteView } from "@/layouts";

import ManageStatic from "@/views/basicConfiguration/baseConfig.vue";
export default {
  path: "/basicConfiguration",
  name: "",
  component: RouteView,
  meta: {
    title: "基础配置",
    isupperLevels: true,
  },
  children: [
    {
      path: "/basicConfiguration/baseConfig",
      name: "basicConfigurationConfig",
      // component: cateList,
      component: ManageStatic,
      meta: {
        title: "基础配置",
        inMenu: false,
        isupperLevels: true,
      },
    },
  
  ],
};

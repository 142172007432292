// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 保存 POST /admin/api-v1/language */
export async function save(
  body: Manage.LanguageSaveReq,
  options?: { [key: string]: any }
) {
  return request<Manage.RVoid>("/admin/api-v1/language", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /admin/api-v1/language/${param0} */
export async function details(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.LanguageDetailsParams,
  options?: { [key: string]: any }
) {
  const { code: param0, ...queryParams } = params;
  return request<Manage.RLanguageDetailsResp>(
    `/admin/api-v1/language/${param0}`,
    {
      method: "GET",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 修改 PUT /admin/api-v1/language/${param0} */
export async function update(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.LanguageUpdateParams,
  body: Manage.LanguageUpdateReq,
  options?: { [key: string]: any }
) {
  const { code: param0, ...queryParams } = params;
  return request<Manage.RVoid>(`/admin/api-v1/language/${param0}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 删除 DELETE /admin/api-v1/language/${param0} */
export async function deleteUsingDelete(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.LanguageDeleteParams,
  options?: { [key: string]: any }
) {
  const { code: param0, ...queryParams } = params;
  return request<Manage.RVoid>(`/admin/api-v1/language/${param0}`, {
    method: "DELETE",
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 列表 GET /admin/api-v1/language/list */
export async function list(options?: { [key: string]: any }) {
  return request<Manage.RListLanguageListResp>("/admin/api-v1/language/list", {
    method: "GET",
    ...(options || {}),
  });
}

/** 分页 GET /admin/api-v1/language/page */
export async function page(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.LanguagePageParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RPageResultLanguagePageResp>(
    "/admin/api-v1/language/page",
    {
      method: "GET",
      params: {
        // pageNo has a default value: 1
        pageNo: "1",
        // pageSize has a default value: 20
        pageSize: "20",
        ...params,
      },
      ...(options || {}),
    }
  );
}

// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 匹配模板 GET /admin/api-v1/goods-template/matcher-template */
export async function matcherTemplate(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsAttrTemplateMatcherTemplateParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RListGoodsAttrResp>(
    "/admin/api-v1/goods-template/matcher-template",
    {
      method: "GET",
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}

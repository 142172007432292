<template>
  <div style="height: 80vh; padding: 20px">
    <iframe :src="iframeUrl" style="width: 100%; height: 100%; border: none" />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { getAppId } from "@/services/api/merchantAppletAdmin";
const route = useRoute();
const url = "https://wx-open-service.esailchem.com/";
const wxOpenUrl = {
  register: `${url}iframe/wx-applet/register`,
  basic: `${url}iframe/wx-applet/basic-info`,
};
const iframeUrl = ref("");
const appId = ref("");
const merchantId = ref();
import { getAuthCode } from "@/services/api/merchantAppletAdmin";
onMounted(() => {
  const { id: ID } = route.query;
  merchantId.value = ID;
  const params = {
    merchantId: merchantId.value,
  };
  getAppId(params).then((res) => {
    const { data } = res;
    appId.value = data;
    getcode();
  });
});
const getcode = () => {
  getAuthCode().then((res) => {
    const {
      data: { serverKey, authCode },
    } = res;
    const url = appId.value
      ? `${wxOpenUrl.basic}?appId=${appId.value}`
      : `${wxOpenUrl.register}?appId=`;
    iframeUrl.value = `${url}&merchantId=${
      merchantId.value
    }&serverKey=${encodeURIComponent(serverKey)}&authCode=${encodeURIComponent(
      authCode
    )}`;
  });
};
</script>
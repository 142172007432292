// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 新增 POST /admin/api-v1/goods-category-merchant/${param0} */
export async function save(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsCategoryMerchantSaveParams,
  body: Manage.CategorySaveMerchantReq,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/goods-category-merchant/${param0}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...queryParams },
      data: body,
      ...(options || {}),
    }
  );
}

/** 详情 GET /admin/api-v1/goods-category-merchant/${param0}/${param1} */
export async function details(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsCategoryMerchantDetailsParams,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, categoryId: param1, ...queryParams } = params;
  return request<Manage.RGoodsCategoryMerchantDetailsRsp>(
    `/admin/api-v1/goods-category-merchant/${param0}/${param1}`,
    {
      method: "GET",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 修改 PUT /admin/api-v1/goods-category-merchant/${param0}/${param1} */
export async function update(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsCategoryMerchantUpdateParams,
  body: Manage.CategorySaveMerchantReq,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, categoryId: param1, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/goods-category-merchant/${param0}/${param1}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...queryParams },
      data: body,
      ...(options || {}),
    }
  );
}

/** 删除 DELETE /admin/api-v1/goods-category-merchant/${param0}/${param1} */
export async function deleteUsingDelete(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsCategoryMerchantDeleteParams,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, categoryId: param1, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/goods-category-merchant/${param0}/${param1}`,
    {
      method: "DELETE",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 列表 GET /admin/api-v1/goods-category-merchant/${param0}/list */
export async function list(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsCategoryMerchantListParams,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RCollectionGoodsCategoryMerchantListRsp>(
    `/admin/api-v1/goods-category-merchant/${param0}/list`,
    {
      method: "GET",
      params: {
        ...queryParams,
      },
      ...(options || {}),
    }
  );
}

/** 排序 PUT /admin/api-v1/goods-category-merchant/${param0}/sort */
export async function sort(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsCategoryMerchantSortParams,
  body: number[],
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RVoid>(
    `/admin/api-v1/goods-category-merchant/${param0}/sort`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      params: { ...queryParams },
      data: body,
      ...(options || {}),
    }
  );
}

// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 上传地址 GET /admin/api-v1/goods-upload-img/upload-url */
export async function uploadImg(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.GoodsUploadImgUploadImgParams,
  options?: { [key: string]: any }
) {
  return request<Manage.RPresignedUrlResponse>(
    "/admin/api-v1/goods-upload-img/upload-url",
    {
      method: "GET",
      params: {
        ...params,
      },
      ...(options || {}),
    }
  );
}

// @ts-ignore
/* eslint-disable */
import request from "@/request/request";

/** 获取小程序 APPID GET /admin/api-v1/merchant-applet/app-id/${param0} */
export async function getAppId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: Manage.MerchantAppletGetAppIdParams,
  options?: { [key: string]: any }
) {
  const { merchantId: param0, ...queryParams } = params;
  return request<Manage.RString>(
    `/admin/api-v1/merchant-applet/app-id/${param0}`,
    {
      method: "GET",
      params: { ...queryParams },
      ...(options || {}),
    }
  );
}

/** 获取小程序OpenServer授权码 GET /admin/api-v1/merchant-applet/open-sever/auth-code */
export async function getAuthCode(options?: { [key: string]: any }) {
  return request<Manage.RAuthCodeResp>(
    "/admin/api-v1/merchant-applet/open-sever/auth-code",
    {
      method: "GET",
      ...(options || {}),
    }
  );
}
